@import "styles/definitions.scss";
@import "styles/texts.scss";
@import "styles/menus.scss";
@import "styles/modal.scss";
html,
body {
  height: 100%;
}

::-moz-selection {
  background-color: var(--primary-variant-color-alpha-60);
}

::selection {
  background-color: var(--primary-variant-color-alpha-60);
}

//BASIC MODAL

.ui.page.modals.dimmer.transition.visible.active {
  z-index: 999999; // This will make our modal bypass the zendesk's widget default z-index
}

.blurring.dimmable > .dimmer {
  background-color: rgba(255, 255, 255, 0.55);
}
.ui.basic.modal > .actions,
.ui.basic.modal > .content,
.ui.basic.modal > .header {
  padding: 1em 0 !important;
  color: black;
}

.ui.small.modal {
  max-width: 350px;
  margin: 0;
}
.ui.small.modal > i.icon,
.ui.modal > img.close-icon {
  margin-left: 94%;
}
.ui.large.modal {
  max-width: 573px;
  margin: 0;
}
.ui.large.modal > i.icon {
  margin-left: 97%;
}
.ui.modal > .actions {
  border-top: 0;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.16);
}
.ui.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.985);
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
  background-color: #fff;
  border-radius: 4px;
  font-family: $specialFont;
}

.ui.form textarea:focus,
.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus {
  border-radius: 4px;
  border-color: $green;
  box-shadow: none;
}

.ui.selection.dropdown:focus,
.ui.selection.dropdown:active,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu:hover {
  border-color: $green;
}

.ui.dropdown .menu {
  z-index: 9999;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: rgba($color: $green, $alpha: 0.74);
}

.cp.input.error,
.ui.input.error input,
.ui.form.error textarea,
.ui textarea.error {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

.cp.input.success,
.ui.input.success input,
.ui.form.success textarea,
.ui textarea.success {
  background: #f6fff6;
  border-color: #b1dabb;
  color: $green;
}

.ui.segment {
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 1rem 2rem;
}

button.link {
  color: #3399f7;
  cursor: pointer;
  background: transparent;
  border: 0 none;
  outline: none;
}
button.link:focus {
  border: 0 none;
}

// INPUTS
.cp.input,
.ui.input input,
.ui.form textarea,
.ui.selection.dropdown,
.ui textarea {
  background-color: #fff;
  border-radius: 4px;
  font-family: $specialFont;
  resize: none;
}

.ui[class*="left action"].input > .button:first-child,
.ui[class*="left action"].input > .buttons:first-child > .button,
.ui[class*="left action"].input > .dropdown:first-child {
  border-radius: 4px;
}
.cp.input {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.ui[class*="right labeled"].icon.button > .icon {
  background-color: transparent;
}
.ui.tiny.basic.icon.right.labeled.button {
  padding: 6px 34px 6px 12px !important;
}

.ui.input input:focus,
.ui.form textarea:focus,
.ui textarea:focus {
  border-radius: 4px;
}

textarea:disabled {
  opacity: 0.45;
}

.ui.action.input > .button,
.ui.action.input > .buttons > .button {
  border-radius: 4px !important;
}

// .ui.button>.icon:not(.button){
//   margin: auto!important;
// }

.react-multi-email {
  border-radius: 4px;
  width: 100%;
  flex: 1;
  cursor: text;
}
.react-multi-email > input {
  display: inline-block;
}
.react-multi-email [data-tag] {
  border-radius: 16px !important;
  font-weight: normal;
  display: inline-block;
}

.ui.button {
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px !important;
  font-family: $specialFont;
  background-color: #efeeea;
}
.ui.button::first-letter {
  text-transform: uppercase;
}
@media only screen and (max-width: 376px) {
  .ui.button {
    font-size: 15px !important;
  }
}

.ui.button:not(.small) {
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 50px;
}

.ui.basic.button {
  background-color: white !important;
}

.ui.blue.basic.button {
  color: $blue !important;
}

.ui.basic.small.button {
  font-size: 14px !important;
}
.ui.small.button {
  font-size: 14px !important;
}

.ui.basic.tiny.button {
  font-size: 14px !important;
  padding: 6px 12px !important;
}
.ui.tiny.button {
  font-size: 14px !important;
  padding: 6px 12px !important;
}

.ui.basic.mini.button {
  font-size: 14px !important;
  padding: 4px 12px !important;
}
.ui.mini.button {
  font-size: 14px !important;
  padding: 4px 12px !important;
}

.ui.button:not(.basic):disabled,
.ui.buttons .disabled.button:not(.basic),
.ui.disabled.active.button:not(.basic),
.ui.disabled.button:not(.basic),
.ui.disabled.button:not(.basic):hover {
  opacity: 1 !important;
  background-color: rgba($color: #000000, $alpha: 0.34) !important;
}

//GREEN
.ui.green.button,
.ui.green.buttons .button {
  background-color: $green;
}

.ui.green.button:active,
.ui.green.buttons .button:active {
  background-color: $greenActive;
}

.ui.green.button:focus,
.ui.green.buttons .button:focus {
  background-color: $greenActive;
}

.ui.green.button:hover,
.ui.green.buttons .button:hover {
  background-color: $greenHover;
}

.ui.inverted.green.button,
.ui.inverted.green.buttons .button {
  color: $green;
  box-shadow: 0 0 0 2px $green inset !important;
}
.ui.inverted.green.button:active,
.ui.inverted.green.buttons .button:active {
  background-color: $greenActive;
}
.ui.inverted.green.button:focus,
.ui.inverted.green.buttons .button:focus {
  background-color: $greenActive;
}
.ui.inverted.green.button:hover,
.ui.inverted.green.buttons .button:hover {
  background-color: $greenHover;
}

.ui.basic.green.button,
.ui.basic.green.buttons .button {
  color: $green !important;
  box-shadow: 0 0 0 1px $green inset !important;
}
.ui.basic.green.button:active,
.ui.basic.green.buttons .button:active {
  color: $greenActive !important;
  box-shadow: 0 0 0 1px $greenActive inset !important;
}
.ui.basic.green.button:focus,
.ui.basic.green.buttons .button:focus {
  color: $greenActive !important;
  box-shadow: 0 0 0 1px $greenActive inset !important;
}
.ui.basic.green.button:hover,
.ui.basic.green.buttons .button:hover {
  color: $greenHover !important;
  box-shadow: 0 0 0 1px $greenHover inset !important;
}

//GREY
.ui.grey.button,
.ui.grey.buttons .button {
  background-color: $grey;
}

.ui.grey.button:active,
.ui.grey.buttons .button:active {
  background-color: $greyActive;
}

.ui.grey.button:hover,
.ui.grey.buttons .button:hover {
  background-color: $greyHover;
}

.ui.inverted.grey.button,
.ui.inverted.grey.buttons .button {
  color: $grey;
  box-shadow: 0 0 0 2px $grey inset !important;
}
.ui.inverted.grey.button:active,
.ui.inverted.grey.buttons .button:active {
  background-color: $greyActive;
}
.ui.inverted.grey.button:hover,
.ui.inverted.grey.buttons .button:hover {
  background-color: $greyHover;
}

.ui.basic.grey.button,
.ui.basic.grey.buttons .button {
  color: $grey;
  box-shadow: 0 0 0 1px $grey inset !important;
}
.ui.basic.grey.button:active,
.ui.basic.grey.buttons .button:active {
  background-color: $greyActive;
}
.ui.basic.grey.button:hover,
.ui.basic.grey.buttons .button:hover {
  background-color: $greyHover;
}

.ui.basic.button.borderless,
.ui.basic.buttons.borderless .button,
.ui.button.borderless {
  border: 0 none !important;
  box-shadow: none !important;
}

.ui.basic.button.borderless:hover,
.ui.basic.buttons.borderless .button:hover,
.ui.button.borderless:hover {
  border: 0 none !important;
  box-shadow: none !important;
}

.ui.basic.button.borderless:active,
.ui.basic.buttons.borderless .button:active,
.ui.button.borderless:active {
  border: 0 none !important;
  box-shadow: none !important;
}

.ui.basic.button.redHover:hover,
.ui.basic.buttons.redHover .button:hover,
.ui.button.redHover:hover {
  box-shadow: 0 0 0 2px $red inset !important;
  background-color: rgba(245, 83, 83, 0.08) !important;
}
.ui.basic.button.blueHover:hover,
.ui.basic.buttons.blueHover .button:hover,
.ui.button.blueHover:hover {
  box-shadow: 0 0 0 2px $blue inset !important;
  background-color: rgba(51, 153, 247, 0.08) !important;
}

.ui.grid > .row.fixed {
  position: fixed;
  width: 100vw !important;
  bottom: 0;
  display: block;
  margin: 0 auto;
  left: 0;
  background-color: #fff;
  z-index: 3;
  padding-left: 1em;
  padding-right: 1em;
}

.ui.basic.button.transparent {
  background-color: transparent !important;
}

// PAGES
.Page > .ui.container .ui.header:first-child {
  text-align: center;
  margin: 2.4em auto;
}

.Page > .ui.container .ui.header:first-child {
  text-align: center;
}

.ui.input > input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  padding: 0 !important;
  margin: 0.5em 0;
  outline: none;
  box-shadow: none;
  //border: 1 solid rgba($color: #000000, $alpha: 0.16);
  //border: 1 solid rgba($color: #000000, $alpha: 0.16);
  border: 0;
  border-radius: 4px;
}

.ui.input > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.ui.input > input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: 0 none;
  height: 16px;
  width: 16px;
  border-radius: 5em;
  background: $green;
  cursor: pointer;

  box-shadow: none;
}
input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  border: 0 none;
  height: 16px;
  width: 16px;
  border-radius: 5em;
  background: $green;
  cursor: pointer;

  box-shadow: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0 none;
  height: 16px;
  width: 16px;
  border-radius: 5em;
  background: $green;
  cursor: pointer;
  margin-top: -5px;
  box-shadow: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #f7f7f7;
  border-radius: 4px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f7f7f7;
  outline: none;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1 solid rgba($color: #000000, $alpha: 0.16);
}

input[type="range"]::-moz-focus-outer,
input[type="range"]::-moz-focus-inner {
  background: #f7f7f7;
  outline: none;
  border: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #367ebd;
}

.ui.labeled.input > .label {
  background-color: rgba($color: #000000, $alpha: 0.03);
  font-weight: normal;
  padding-left: 2em;
  padding-right: 2em;
  font-size: $textFontSizeSmall;
  color: $black;
  font-family: $specialFont;
  border-radius: 4px;
  display: flex;
  align-content: center;
  line-height: unset;
}

.ui.checkbox .box,
.ui.checkbox label {
  font-family: $specialFont;
  font-size: $textFontSizeNormal;
  color: rgba($color: #000000, $alpha: 0.54);
}
.ui.avatar.image,
.ui.avatar.image img,
.ui.avatar.image svg,
.ui.avatar.images .image,
.ui.avatar.images img,
.ui.avatar.images svg {
  object-fit: cover;
}

// DIVIDER

.ui.divider {
  margin: 2rem 0;
}

// ACCORDION

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  border-radius: 4px;
  background: #fff;
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  font-family: $commonFont;
  font-size: $textFontSizeNormal;
  color: rgba($color: #000000, $alpha: 0.54);
  font-weight: 500;
}

.ui.accordion .accordion {
  margin-top: 0;
}

.bullet {
  vertical-align: -0.125em;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.bullet .bulletCenter {
  width: 50%;
  height: 50%;
  border: 1px solid white;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -25% 0 0 -25%;
  visibility: hidden;
}

.bulletsquare {
  vertical-align: -0.125em;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  border: 1px solid rgba(0, 0, 0, 0.16);
  display: inline-block;
  position: relative;
}

.bulletsquare .bulletCenter {
  width: 50%;
  height: 50%;
  border: 1px solid rgb(255, 255, 255);
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -25% 0 0 -25%;
  visibility: hidden;
}

.active.title .bullet {
  vertical-align: -0.125em;
  background-color: $green;
}

.active.title .bulletsquare {
  vertical-align: -0.125em;
  background-color: $green;
}

.active.title .bullet .bulletCenter {
  visibility: visible;
}

.InputOption.selected .bullet .bulletCenter {
  visibility: visible;
}

.active.title .bulletsquare .bulletCenter {
  visibility: visible;
}

.cookieConsent {
  z-index: 11 !important;
}

.cookieConsent > div {
  flex: 1 0 200px !important;
}

.cookieConsent > button {
  align-self: center;
}

// TABLE

.ui.table {
  border: hidden;
  font-family: $commonFont;
}
.ui.table td {
  padding: 0.58571429em 0.78571429em;
}
.ui.table th {
  background: transparent !important;
}
.ui.table thead th {
  background: #fff;
  font-weight: 300;
  padding: 7px 7px;
}
.ui.table tbody tr td {
  padding-left: 7px;
}
.ui.table thead tr:first-child > * {
  color: rgba($color: #000000, $alpha: 0.54);
  border-left: none;
}
tbody {
  border-left: none;
}
.ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: none;
}
thead tr .text {
  text-transform: uppercase;
  font-weight: 700;
  color: $black;
}
td .status-view {
  margin: 0;
}
.ui.sortable.table thead th.sorted,
.ui.sortable.table thead th.sorted:hover {
  background: #fff;
  display: flex;
}
.ui.sortable.table thead th:hover {
  background: #fff;
}

.displayNone {
  display: none;
}

.cp-table-header {
  display: flex;
  padding-bottom: 1em;
}
.cp-table-row {
  display: flex;
  padding: 0.5em 0;
}
.cp-table-cell {
  margin: auto 28px auto 0;
}

.cp-table-cell-hover:hover {
  background-color: rgba($color: #000000, $alpha: 0.02);
}

// CONFIRM ALERT

.react-confirm-alert-body {
  font-family: $specialFont !important;
  width: 380px !important;
  padding: 2em !important;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.13) !important;
  border-radius: 4px !important;
  color: $black !important;
}

.react-confirm-alert-overlay {
  z-index: 1000000 !important;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  margin-top: 2em !important;
}

.react-confirm-alert-body > h1 {
  font-size: $textFontSizeNormal !important;
}

.react-confirm-alert-button-group > button {
  border-radius: 4px !important;
  background-color: $black !important;
}

.react-confirm-alert-button-group > button:last-child {
  background-color: $red !important;
}

// REACT CALENDAR

.Calendar__sectionWrapper {
  min-height: 21.8em !important;
}

.react-calendar__tile--active {
  background: $grey !important;
}

.react-calendar__tile--now {
  background: var(--primary-color) !important;
}

.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before {
  background-color: $green !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box::before,
.ui.toggle.checkbox input:focus:checked ~ label::before {
  background-color: $green !important;
}
.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 220px;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}
.skip-link:focus {
  top: 0;
}
